<template>
  <v-card class="mx-auto" :disabled="ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                :loading="btn_xlsx_ldg"
                :disabled="tbl.length == 0"
                @click.prevent="downloadXlsx"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLSX'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" xs="12" md="2">
          <v-select
            label="Tipo de servicio"
            v-model="rs_service_type"
            :items="rs_service_type_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <v-select
            label="Confirmación de servicio"
            v-model="confirm"
            :items="confirm_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col cols="12" xs="12" md="2">
          <v-switch
            :label="'Intervalo'"
            v-model="interval"
            class="mt-0"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col v-if="interval" cols="12" xs="12" md="3">
          <DatePicker
            :label="'F. carta inicial'"
            :model.sync="start_date"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col v-if="interval" cols="12" xs="12" md="3">
          <DatePicker
            :label="'F. carta final'"
            :model.sync="end_date"
            :disabled="tbl.length > 0"
          />
        </v-col>
        <v-col v-if="tbl.length == 0" cols="12">
          <v-btn
            block
            small
            color="success"
            @click.prevent="getTbl"
            :loading="ldg"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn
            block
            small
            color=""
            @click.prevent="
              tbl = [];
              tbl_srch = '';
            "
          >
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="tbl.length > 0">
        <v-col cols="12" sm="12" md="3">
          <ViewData label="Monto" :value="numberFormat(amount)" />
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <ViewData label="Registros" :value="tbl.length" />
        </v-col>
        <v-col cols="12" xs="12" md="6">
          <v-text-field
            v-model="tbl_srch"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_hdrs"
            :search="tbl_srch"
            :items="tbl"
            :loading="ldg"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key + 1" />
            </template>
            <template v-slot:item.assured_amount="{ item }">
              {{ numberFormat(item.assured_amount) }}
            </template>
            <template v-slot:item.confirm_txt="{ item }">
              <v-icon v-if="item.confirm_txt == 'SI'" color="success" small>
                mdi-check
              </v-icon>
              <v-icon v-if="item.confirm_txt == 'NO'" color="warning" small>
                mdi-close
              </v-icon>
              <v-icon
                v-if="item.confirm_txt == 'PENDIENTE'"
                color="error"
                small
              >
                mdi-null
              </v-icon>
            </template>
            <template v-slot:item.rs.external="{ item }">
              <v-icon v-if="item.rs.external == 1" color="success" small>
                mdi-check
              </v-icon>
              <v-icon v-else small> mdi-close </v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="info"
                    :to="{
                      name: 'pend_cobros.detalle',
                      params: { rs_service_id: item.id },
                    }"
                    v-on="on"
                    x-small
                    icon
                  >
                    <v-icon> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Detalle'" />
              </v-tooltip>

              <!-- <v-tooltip
                v-if="
                  login.permissions.pending_services.update &&
                  !item.rs_service_bill
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    color="amber darken-2"
                    v-on="on"
                    x-small
                    dark
                    fab
                    :to="{
                      name: 'pend_cobros.documentacion',
                      params: { rs_service_id: item.id },
                    }"
                  >
                    <v-icon> mdi-file-code </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cargar documentación'" />
              </v-tooltip> -->

              <!-- <v-tooltip
                v-if="
                  login.permissions.pending_services.create &&
                  !item.doctor.bank_account_pdf
                "
                left
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    :color="
                      item.doctor_prerevision_rejected
                        ? 'error'
                        : 'amber darken-3'
                    "
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="doctorPrerevisionDialog(item)"
                  >
                    <v-icon> mdi-file-sign </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Cargar cuenta bancaria'" />
              </v-tooltip> -->

              <!-- <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    :color="
                      item.rs_service_notes_total > 0
                        ? 'pink darken-1'
                        : 'grey lighten-1'
                    "
                    v-on="on"
                    x-small
                    dark
                    fab
                    @click.prevent="noteDialog(item)"
                  >
                    <v-icon> mdi-note-text </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Nota(s) administrativa(s)'" />
              </v-tooltip> -->
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- <v-dialog
      v-model="doctor_prerevision_dialog"
      scrollable
      persistent
      max-width="900"
    >
      <v-card
        tile
        :disabled="doctor_prerevision_dialog_loading"
        :loading="doctor_prerevision_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> MÉDICO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_prerevision_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="doctor_prerevision_data">
          <v-row>
            <v-col cols="12" />
            <v-col
              v-if="doctor_prerevision_data.doctor_prerevision_rejected"
              cols="12"
            >
              <v-alert type="error" class="mb-0" dense>
                <v-row dense>
                  <v-col cols="12">
                    <b>CARGA PREVIA RECHAZADA</b>
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Banco"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected.bank
                          .bank
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="Número de cuenta"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .account_number
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <ViewData
                      label="CLABE"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .clabe
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <span
                      class="text-caption font-weight-bold"
                      v-text="'Cuenta bancaria (PDF)'"
                    />
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          small
                          v-on="on"
                          :href="
                            url_documents +
                            '/doctors/' +
                            doctor_prerevision_data.doctor_prerevision_rejected
                              .bank_account_pdf
                          "
                          target="_blank"
                        >
                          <v-icon small>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span v-text="'Ver'" />
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <ViewData
                      label="Observación"
                      :value="
                        doctor_prerevision_data.doctor_prerevision_rejected
                          .observation
                      "
                    />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'GENERAL'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Nombre"
                        :value="`IDM ${doctor_prerevision_data.doctor_id} | ${doctor_prerevision_data.doctor_name}`"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Especialidad"
                        :value="doctor_prerevision_data.specialty_type"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Consult. | Hosp."
                        :value="doctor_prerevision_data.provider_trade_name"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'BANCO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="doctor_prerevision_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-autocomplete
                          label="Banco*"
                          v-model="doctor_prerevision_data.bank_id"
                          :items="banks"
                          item-value="id"
                          :item-text="(v) => v.bank"
                          :loading="banks_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Número de cuenta*"
                          v-model="doctor_prerevision_data.account_number"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="CLABE*"
                          v-model="doctor_prerevision_data.clabe"
                          type="text"
                          maxlength="18"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          label="Cuenta bancaria (PDF)*"
                          v-model="
                            doctor_prerevision_data.document_bank_account_0
                          "
                          accept=".pdf"
                          :rules="rules.required"
                          show-size
                          dense
                          prepend-icon=""
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="amber darken-3"
                          dark
                          @click.prevent="doctorPrerevisionSubmit"
                          small
                        >
                          CARGAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- <v-dialog v-model="note_dialog" scrollable persistent max-width="900">
      <v-card
        tile
        :disabled="note_dialog_loading"
        :loading="note_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> NOTA(S) ADMINISTRATIVA(S) </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="note_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="note_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="`SERVICIO | FOLIO ${note_data.folio_full}`"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Médico"
                        :value="
                          'IDM ' +
                          note_data.doctor_id +
                          ' | ' +
                          note_data.doctor_name
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        label="Asegurado"
                        :value="note_data.insured_full_name"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>

                    <v-col v-if="rs_service_notes" cols="12">
                      <v-row
                        v-for="(rs_service_note, i) in rs_service_notes"
                        :key="i"
                        dense
                      >
                        <v-col cols="12">
                          <ViewData
                            :label="`${i + 1}. Nota administrativa`"
                            :value="rs_service_note.rs_service_note"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                          <ViewData
                            label="Usuario"
                            :value="rs_service_note.created_by.email"
                          />
                        </v-col>
                        <v-col cols="12" xs="12" md="6">
                          <ViewData
                            label="F. creación"
                            :value="rs_service_note.created_at"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12">
                      <v-form
                        v-on:submit.prevent
                        ref="note_form"
                        lazy-validation
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <v-textarea
                              v-model="note_data.rs_service_note"
                              label="Nueva nota administrativa*"
                              dense
                              rows="2"
                              :rules="rules.required"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-btn
                              block
                              color="pink darken-1"
                              dark
                              @click.prevent="noteSubmit"
                              small
                            >
                              AGREGAR
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  dateTimeNow,
  base64ToArrayBuffer,
  dateTimeToFile,
  msgAlert,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    DatePicker,
    ViewData,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      amount: 0,
      tbl: [],
      ldg: false,
      tbl_srch: "",
      tbl_hdrs: [
        {
          value: "key",
          text: "#",
          filterable: true,
        },
        {
          value: "folio",
          text: "Folio",
          filterable: true,
        },
        {
          value: "rs.rs_type.type",
          text: "Tipo",
          filterable: true,
        },
        {
          value: "rs.insured.credential",
          text: "N. credencial",
          filterable: true,
        },
        {
          value: "rs.enrollment",
          text: "ID SM",
          filterable: true,
        },
        {
          value: "rs.insured.full_name",
          text: "Asegurado",
          filterable: true,
        },
        {
          value: "rs.insured.contractor.name",
          text: "Contratante",
          filterable: true,
        },
        {
          value: "rs.insured.office_address",
          text: "Oficina",
          filterable: true,
        },
        {
          value: "provider.trade_name",
          text: "Proveedor | Consultorio",
          filterable: true,
        },
        {
          value: "provider.provider_type.provider_type",
          text: "P. Tipo",
          filterable: true,
        },
        {
          value: "doctor.id",
          text: "IDM",
          filterable: true,
        },
        {
          value: "doctor.full_name",
          text: "Médico",
          filterable: true,
        },
        {
          value: "specialty_type.specialty_type",
          text: "Especialidad",
          filterable: true,
        },
        {
          value: "letter_generated",
          text: "F. carta",
          filterable: true,
        },
        {
          value: "assured_amount",
          text: "Monto",
          filterable: true,
        },
        {
          text: "Terminado",
          filterable: true,
          value: "rs.discharged",
        },
        {
          text: "Ingreso Folio",
          filterable: true,
          value: "rha.folio",
        },
        {
          text: "INFO",
          filterable: true,
          value: "rs.external",
        },
        {
          text: "Confirmación",
          filterable: true,
          value: "confirm_txt",
        },
        {
          text: "Notas admin.",
          filterable: true,
          value: "rs_service_notes_total",
        },
        {
          text: "",
          value: "action",
          sortable: false,
          width: "245px",
        },
      ],
      rs_service_type: 3,
      rs_service_type_opt: [
        {
          id: 1,
          name: "PROVEEDORES",
        },
        {
          id: 2,
          name: "MEDICOS",
        },
        {
          id: 3,
          name: "TODOS",
        },
      ],
      confirm: 1,
      confirm_opt: [
        {
          id: 1,
          name: "SI",
        },
        {
          id: 0,
          name: "NO",
        },
        {
          id: null,
          name: "PENDIENTE",
        },
        {
          id: 3,
          name: "TODOS",
        },
      ],
      interval: true,
      start_date: null,
      end_date: null,
      btn_xlsx_ldg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      //------------------
      // url_documents: URL_DOCUMENTS,
      // rules: rules(),
      // doctor_prerevision_data: null,
      // doctor_prerevision_dialog: false,
      // doctor_prerevision_dialog_loading: false,
      // banks: [],
      // banks_loading: true,
      // note_data: null,
      // note_dialog: false,
      // note_dialog_loading: false,
      // rs_service_notes: null,
      // item: null,
    };
  },
  methods: {
    getTbl() {
      this.ldg = true;
      this.tbl = [];
      Axios.post(
        URL_API + "/rss/services/bills/pending",
        {
          confirm: this.confirm,
          rs_service_type: this.rs_service_type,
          interval: this.interval,
          start_date: this.start_date,
          end_date: this.end_date,
        },
        headersToken(this.login.token)
      ).then((rsp) => {
        this.amount = rsp.data.data.amount;
        this.tbl = rsp.data.data.rs_services;
        this.ldg = false;
      });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    downloadXlsx() {
      this.btn_xlsx_ldg = true;

      Axios.post(
        URL_API + "/rss/services/bills/pending/xlsx",
        {
          items: this.tbl,
        },
        headersToken(this.login.token)
      ).then((rsp) => {
        if (rsp.data.success) {
          this.fileDownload(rsp.data.data, "xlsx", "S_PEND_COBRO");
          this.btn_xlsx_ldg = false;
        } else {
          this.btn_xlsx_ldg = false;
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }
      });
    },
    fileDownload(base64, ext, name) {
      const blob = new Blob([base64ToArrayBuffer(base64)], {
        type: "application/" + ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + name + "_" + dateTimeToFile() + "." + ext;
      downloadLink.click();
    },
    // doctorPrerevisionDialog(item) {
    //   this.doctor_prerevision_data = {
    //     id: null,
    //     active: true,
    //     bank_id: null,
    //     account_number: "",
    //     clabe: "",
    //     document_bank_account_0: null,
    //     doctor_id: item.doctor_id,
    //     doctor_name: item.doctor_name,
    //     specialty_type: item.specialty_type.specialty_type,
    //     provider_trade_name: item.provider_trade_name,
    //     doctor_prerevision_rejected: item.doctor_prerevision_rejected,
    //     doctor_prerevision_rejected_id: item.doctor_prerevision_rejected
    //       ? item.doctor_prerevision_rejected.id
    //       : null,
    //   };
    //   this.doctor_prerevision_dialog_loading = false;
    //   this.doctor_prerevision_dialog = true;
    // },
    // doctorPrerevisionSubmit() {
    //   if (this.$refs.doctor_prerevision_form.validate()) {
    //     this.$swal
    //       .fire(msgConfirm(`¿Confirma cargar el recibo de pago?`))
    //       .then((resp) => {
    //         if (resp.isConfirmed) {
    //           this.doctor_prerevision_dialog_loading = true;
    //           Axios.post(
    //             `${URL_API}/doctors/prerevision`,
    //             toFormData(this.doctor_prerevision_data),
    //             headersToken(this.login.token)
    //           ).then((resp) => {
    //             this.$swal.fire(
    //               msgAlert(
    //                 resp.data.success ? "success" : "error",
    //                 resp.data.message
    //               )
    //             );

    //             if (resp.data.success) {
    //               this.doctor_prerevision_dialog = false;
    //               this.getTbl();
    //             } else {
    //               console.log(resp.data.message);
    //             }

    //             this.doctor_prerevision_dialog_loading = false;
    //           });
    //         }
    //       });
    //   } else {
    //     this.$swal.fire(
    //       msgAlert(
    //         "error",
    //         "Formulario invalido, revisa los detalles señalados"
    //       )
    //     );
    //   }
    // },
    // noteDialog(item) {
    //   this.rs_service_notes = null;
    //   this.item = item;
    //   this.note_data = {
    //     id: null,
    //     rs_service_note: null,
    //     rs_service_id: item.id,
    //     folio_full: item.folio_full,
    //     doctor_id: item.doctor_id,
    //     doctor_name: item.doctor_name,
    //     insured_full_name: item.rs.insured_full_name,
    //   };
    //   this.note_dialog_loading = true;
    //   this.note_dialog = true;

    //   Axios.post(
    //     `${URL_API}/rss/services/notes`,
    //     {
    //       rs_service_id: item.id,
    //     },
    //     headersToken(this.login.token)
    //   ).then((resp) => {
    //     this.rs_service_notes = resp.data.data;
    //     this.note_dialog_loading = false;
    //   });
    // },
    // noteSubmit() {
    //   if (this.$refs.note_form.validate()) {
    //     this.$swal
    //       .fire(msgConfirm(`¿Confirma agregar nota administrativa?`))
    //       .then((resp) => {
    //         if (resp.isConfirmed) {
    //           this.note_dialog_loading = true;
    //           Axios.post(
    //             `${URL_API}/rss/services/notes/save`,
    //             this.note_data,
    //             headersToken(this.login.token)
    //           ).then((resp) => {
    //             this.$swal.fire(
    //               msgAlert(
    //                 resp.data.success ? "success" : "error",
    //                 resp.data.message
    //               )
    //             );

    //             if (resp.data.success) {
    //               this.item.rs_service_notes_total++;
    //               this.note_dialog = false;
    //             } else {
    //               console.log(resp.data.message);
    //             }

    //             this.note_dialog_loading = false;
    //           });
    //         }
    //       });
    //   } else {
    //     this.$swal.fire(
    //       msgAlert(
    //         "error",
    //         "Formulario invalido, revisa los detalles señalados"
    //       )
    //     );
    //   }
    // },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.start_date = dateTimeNow().substring(0, 8) + "01";
    this.end_date = dateTimeNow().substring(0, 10);

    // Axios.get(
    //   `${URL_API}/catalog?name=banks&no_order=false`,
    //   headersToken(this.login.token)
    // ).then((resp) => {
    //   this.banks = resp.data.data;
    //   this.banks_loading = false;
    // });
  },
};
</script>